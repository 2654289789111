import { NavItem } from './nav-item/nav-item';

export const adminItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'aperture',
    route: '/dashboard',
  },
 
  {
    navCap: 'Quick Actions',
  },

  
  {
    displayName: 'Blocks',
    iconName: 'building',
    route: 'apps/blocks',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/blocks/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/blocks/list',
      }
    ],
  },


  {
    displayName: 'Units',
    iconName: 'building-skyscraper',
    route: 'apps/units',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/units/add',
      },
      {
        displayName: 'Units list',
        iconName: 'point',
        route: 'apps/units/list',
      }
    ],
  },
  {
    displayName: 'House Owners',
    iconName: 'user-cog',
    route: 'apps/houseowners',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/houseowners/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/houseowners/list',
      }
    ],
  },
  {
    displayName: 'Tenants',
    iconName: 'users-group',
    route: 'apps/tenants',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/tenants/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/tenants/list',
      }
    ],
  },


  {
    navCap: 'Finance',
  },

  {
    displayName: 'Transactions',
    iconName: 'coin',
    route: 'apps/transactions',
    children: [
      {
        displayName: 'Create Invoice',
        iconName: 'point',
        route: 'apps/transactions/create-invoice',
      },
      // {
      //   displayName: 'Recurrring Invoice',
      //   iconName: 'point',
      //   route: 'apps/transactions/recurring-invoices',
      // },
      {
        displayName: 'Invoice List',
        iconName: 'point',
        route: 'apps/transactions/invoices',
      },
      {
        displayName: 'Credit Note',
        iconName: 'point',
        route: 'apps/transactions/credit-note',
      },
     
    ],
  },
  {
    displayName: 'Payments',
    iconName: 'cash',
    route: 'apps/payments',
    children: [
      {
        displayName: 'Payments',
        iconName: 'point',
        route: 'apps/payments/payments',
      },
      // {
      //   displayName: 'Suspense Accounts',
      //   iconName: 'point',
      //   route: 'apps/payments/suspense',
      // },
      // {
      //   displayName: 'Wallets',
      //   iconName: 'point',
      //   route: 'apps/payments/wallet',
      // }
    ],
  },
  {
    displayName: 'Expense',
    iconName: 'receipt-2',
    route: 'apps/expenses',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/expenses/add',
      },
      {
        displayName: 'Expenses',
        iconName: 'point',
        route: 'apps/expenses/list',
      },
      
    ],
  },
  
  
  // {
  //   displayName: 'Banks',
  //   iconName: 'building-bank',
  //   route: 'apps/products',
  //   children: [
  //     {
  //       displayName: 'Add',
  //       iconName: 'point',
  //       route: 'apps/products/add',
  //     },
  //     {
  //       displayName: 'List',
  //       iconName: 'point',
  //       route: 'apps/products/list',
  //     }
  //   ],
  // },
  // {
  //   displayName: 'Budget',
  //   iconName: 'briefcase',
  //   route: 'apps/products',
  //   children: [
  //     {
  //       displayName: 'Budget',
  //       iconName: 'point',
  //       route: 'apps/products/add',
  //     }
  //   ],
  // },
  

  {
    displayName: 'Service Providers',
    iconName: 'user-search',
    route: 'apps/suppliers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/suppliers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/suppliers/list',
      }
    ],
  },



  {
    navCap: 'Utility Billing',
  },

  {
    displayName: 'Water',
    iconName: 'droplet',
    route: 'apps/utility/water',
  },
  {
    displayName: 'Electricty Tarrifs',
    iconName: 'settings-star',
    route: 'apps/utility/electricity-tarrifs',
  },
  
  {
    displayName: 'Power Meters',
    iconName: 'bolt',
    route: 'apps/utility/electricity',
  },
  {
    displayName: 'Power Purchases',
    iconName: 'plug',
    route: 'apps/utility/power-purchases',
  },
  
  
  {
    navCap: 'Reports',
  },

  {
    displayName: 'Reports',
    iconName: 'file-report',
    route: 'apps/reports',
    children: [
      {
        displayName: 'Finance Summary',
        iconName: 'point',
        route: 'apps/reports/finance-summary',
      },
      {
        displayName: 'Rental Collection Report',
        iconName: 'point',
        route: 'apps/reports/rental-collection',
      }
    ],
  },


  {
    navCap: 'Services',
  },

  {
    displayName: 'Service Request',
    iconName: 'tool',
    route: 'apps/services/requests',
  },
  
  
  

  {
    navCap: 'Settings',
  },

 


  // {
  //   displayName: 'Add Employee',
  //   iconName: 'user-plus',
  //   route: 'apps/add-employee',
  // },

  {
    displayName: 'Employees',
    iconName: 'users',
    route: 'apps/employee',
  },

  
  {
    displayName: 'Companies',
    iconName: 'home-2',
    route: 'apps/companies',
    children: [
      // {
      //   displayName: 'Add',
      //   iconName: 'point',
      //   route: 'apps/companies/add',
      // },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/companies/list',
      }
    ],
  },


  {
    displayName: 'Properties',
    iconName: 'building-estate',
    route: 'apps/properties',
    children: [
      {
        displayName: 'Add Property',
        iconName: 'point',
        route: 'apps/properties/add',
      },
      {
        displayName: 'Properties',
        iconName: 'point',
        route: 'apps/properties/list',
      }
    ],
  },
  {
    displayName: 'Categories',
    iconName: 'layout',
    route: 'widgets',
    children: [
      {
        displayName: 'Add Category',
        iconName: 'point',
        route: 'categories/list',
      },
     
    ],
  },
  
 
  {
    displayName: 'Roles',
    iconName: 'lock-access',
    route: 'apps/roles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/roles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/roles/list',
      }
    ],
  },

  {
    displayName: 'Configurations',
    iconName: 'settings-plus',
    route: 'apps/configurations',
    children: [
      {
        displayName: 'Invoicing Task',
        iconName: 'point',
        route: 'apps/configurations/invoicing',
      },
      {
        displayName: 'Penalty Task',
        iconName: 'point',
        route: 'apps/configurations/penalty',
      },
      {
        displayName: 'Port Forwarding',
        iconName: 'point',
        route: 'apps/configurations/forwarding',
      }
    ],
  },

 
  {
    displayName: 'Documents',
    iconName: 'books',
    route: 'apps/documents',
    children: [
   
      {
        displayName: 'Documents List',
        iconName: 'point',
        route: 'apps/documents/list',
      }
    ],
  },



  {
    displayName: 'Account Setting',
    iconName: 'user-circle',
    route: 'apps/users/account',
  },

  //   {
  //   navCap: 'Forms',
  // },
  // {
  //   displayName: 'Form elements',
  //   iconName: 'apps',
  //   route: 'forms/forms-elements',
  //   children: [
  //     {
  //       displayName: 'Autocomplete',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/autocomplete',
  //     },
  //     {
  //       displayName: 'Button',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/button',
  //     },
  //     {
  //       displayName: 'Checkbox',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/checkbox',
  //     },
  //     {
  //       displayName: 'Radio',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/radio',
  //     },
  //     {
  //       displayName: 'Datepicker',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/datepicker',
  //     },
  //   ],
  // },
  // {
  //   displayName: 'Form Layouts',
  //   iconName: 'file-description',
  //   route: '/forms/form-layouts',
  // },
  // {
  //   displayName: 'Form Horizontal',
  //   iconName: 'box-align-bottom',
  //   route: '/forms/form-horizontal',
  // },
  // {
  //   displayName: 'Form Vertical',
  //   iconName: 'box-align-left',
  //   route: '/forms/form-vertical',
  // },
  // {
  //   displayName: 'Form Wizard',
  //   iconName: 'files',
  //   route: '/forms/form-wizard',
  // },
  // {
  //   navCap: 'Tables',
  // },
  // {
  //   displayName: 'Tables',
  //   iconName: 'layout',
  //   route: 'tables',
  //   children: [
  //     {
  //       displayName: 'Basic Table',
  //       iconName: 'point',
  //       route: 'tables/basic-table',
  //     },
  //     {
  //       displayName: 'Dynamic Table',
  //       iconName: 'point',
  //       route: 'tables/dynamic-table',
  //     },
  //     {
  //       displayName: 'Expand Table',
  //       iconName: 'point',
  //       route: 'tables/expand-table',
  //     },
  //     {
  //       displayName: 'Filterable Table',
  //       iconName: 'point',
  //       route: 'tables/filterable-table',
  //     },
  //     {
  //       displayName: 'Footer Row Table',
  //       iconName: 'point',
  //       route: 'tables/footer-row-table',
  //     },
  //     {
  //       displayName: 'HTTP Table',
  //       iconName: 'point',
  //       route: 'tables/http-table',
  //     },
  //     {
  //       displayName: 'Mix Table',
  //       iconName: 'point',
  //       route: 'tables/mix-table',
  //     },
  //     {
  //       displayName: 'Multi Header Footer',
  //       iconName: 'point',
  //       route: 'tables/multi-header-footer-table',
  //     },
  //     {
  //       displayName: 'Pagination Table',
  //       iconName: 'point',
  //       route: 'tables/pagination-table',
  //     },
  //     {
  //       displayName: 'Row Context Table',
  //       iconName: 'point',
  //       route: 'tables/row-context-table',
  //     },
  //     {
  //       displayName: 'Selection Table',
  //       iconName: 'point',
  //       route: 'tables/selection-table',
  //     },
  //     {
  //       displayName: 'Sortable Table',
  //       iconName: 'point',
  //       route: 'tables/sortable-table',
  //     },
  //     {
  //       displayName: 'Sticky Column',
  //       iconName: 'point',
  //       route: 'tables/sticky-column-table',
  //     },
  //     {
  //       displayName: 'Sticky Header Footer',
  //       iconName: 'point',
  //       route: 'tables/sticky-header-footer-table',
  //     },
  //   ],
  // },
  // {
  //   displayName: 'Data table',
  //   iconName: 'border-outer',
  //   route: '/datatable/kichen-sink',
  // },
  // {
  //   navCap: 'Chart',
  // },
  // {
  //   displayName: 'Line',
  //   iconName: 'chart-line',
  //   route: '/charts/line',
  // },
  // {
  //   displayName: 'Gredient',
  //   iconName: 'chart-arcs',
  //   route: '/charts/gredient',
  // },
  // {
  //   displayName: 'Area',
  //   iconName: 'chart-area',
  //   route: '/charts/area',
  // },
  // {
  //   displayName: 'Candlestick',
  //   iconName: 'chart-candle',
  //   route: '/charts/candlestick',
  // },
  // {
  //   displayName: 'Column',
  //   iconName: 'chart-dots',
  //   route: '/charts/column',
  // },
  // {
  //   displayName: 'Doughnut & Pie',
  //   iconName: 'chart-donut-3',
  //   route: '/charts/doughnut-pie',
  // },
  // {
  //   displayName: 'Radialbar & Radar',
  //   iconName: 'chart-radar',
  //   route: '/charts/radial-radar',
  // },
  // {
  //   navCap: 'UI',
  // },
  // {
  //   displayName: 'Ui Components',
  //   iconName: 'box',
  //   route: 'ui-components',
  //   children: [
  //     {
  //       displayName: 'Badge',
  //       iconName: 'point',
  //       route: 'ui-components/badge',
  //     },
  //     {
  //       displayName: 'Expansion Panel',
  //       iconName: 'point',
  //       route: 'ui-components/expansion',
  //     },
  //     {
  //       displayName: 'Chips',
  //       iconName: 'point',
  //       route: 'ui-components/chips',
  //     },
  //     {
  //       displayName: 'Dialog',
  //       iconName: 'point',
  //       route: 'ui-components/dialog',
  //     },
  //     {
  //       displayName: 'Lists',
  //       iconName: 'point',
  //       route: 'ui-components/lists',
  //     },
  //     {
  //       displayName: 'Divider',
  //       iconName: 'point',
  //       route: 'ui-components/divider',
  //     },
  //     {
  //       displayName: 'Menu',
  //       iconName: 'point',
  //       route: 'ui-components/menu',
  //     },
  //     {
  //       displayName: 'Paginator',
  //       iconName: 'point',
  //       route: 'ui-components/paginator',
  //     },
  //     {
  //       displayName: 'Progress Bar',
  //       iconName: 'point',
  //       route: 'ui-components/progress',
  //     },
  //     {
  //       displayName: 'Progress Spinner',
  //       iconName: 'point',
  //       route: 'ui-components/progress-spinner',
  //     },
  //     {
  //       displayName: 'Ripples',
  //       iconName: 'point',
  //       route: 'ui-components/ripples',
  //     },
  //     {
  //       displayName: 'Slide Toggle',
  //       iconName: 'point',
  //       route: 'ui-components/slide-toggle',
  //     },
  //     {
  //       displayName: 'Slider',
  //       iconName: 'point',
  //       route: 'ui-components/slider',
  //     },
  //     {
  //       displayName: 'Snackbar',
  //       iconName: 'point',
  //       route: 'ui-components/snackbar',
  //     },
  //     {
  //       displayName: 'Tabs',
  //       iconName: 'point',
  //       route: 'ui-components/tabs',
  //     },
  //     {
  //       displayName: 'Toolbar',
  //       iconName: 'point',
  //       route: 'ui-components/toolbar',
  //     },
  //     {
  //       displayName: 'Tooltips',
  //       iconName: 'point',
  //       route: 'ui-components/tooltips',
  //     },
  //   ],
  // },
 

];
